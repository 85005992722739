@import '../assets/sharedStyles.scss';
.pricing-page {
  background-image: url("../assets/images/running.png");
  overflow-y: auto;
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.packaging-header {
  cursor: default;
  color: $font-dark;
  font-family: "Cardo", serif;
  font-size: 38px;
  text-align: center;
  margin-top: 30px;
  font-weight: 500;
  line-height: 42px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.package-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
}

.package-image {
  height: 240px;
  width: 200px;
  border-radius: 4px;
  margin-bottom: -50px;
  z-index: 80;
  border: 2px solid $font-dark;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
  background-size: 100%;

  &__0 {
    background-image: url('../assets/images/pricing/pkg0.png');
  }

  &__1 {
    background-image: url('../assets/images/pricing/pkg1.png');
  }
  &__2 {
    background-image: url('../assets/images/pricing/pkg2.png');
  }
  &__3 {
    background-image: url('../assets/images/pricing/pkg3.png');
  }
  &__4 {
    background-image: url('../assets/images/pricing/pkg4.png');
  }
  &__5 {
    background-image: url('../assets/images/pricing/pkg5.png');
  }
  
}

.details-wrapper {
  background-color: $font-dark;
  padding: 24px 30px;
  width: 300px;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: $font-light;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);

  h2 {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    margin: 50px 0 8px;
    text-align: center;
  }

  h3 {
  font-family: "Cardo", serif;
  font-size: 26px;
  
  }

  li {
    font-family: "Montserrat", sans-serif;
    font-weight: 220;
    font-size: 18px;
    line-height: 24px;
    margin: 10px;
    list-style: circle;
  }
}

.additional-details {
  cursor: default;
  display: flex;
  justify-content: center;
  text-align: center;
  color: $font-dark;
  font-family: "Montserrat", sans-serif;
  line-height: 22px;
  font-size: 12px;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 10px 0;

  p {
    max-width: 800px;
  }
}

.pricing-contact-button {
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: #98919A;
  width: 170px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Cardo", serif;
  font-size: 24px;
  color: $font-light;
  text-decoration: none;
  margin-bottom: 20px;

  &:active {
    background-color: inherit;
    color: $font-light;
    text-decoration: none;
  }

  &:hover {
    color: white;
    background-color: #b4afb5;
  }
}

@media screen and (min-width: 680px) {
  .grid-container {
    margin-top: 40px;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  .details-wrapper {
    height: 820px;
  }
}

@media screen and (min-width: 1000px) {
  .grid-container {

    grid-template-columns: repeat(3, 1fr);
  }
}