@import '../assets/sharedStyles.scss';
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #f4f3e9;
}
.pricing-header {
  cursor: default;
  position: relative;
  margin-bottom: -40px;
  font-family: "Cardo", serif;
  font-size: 38px;
  background-color: $background-color-dark;
  width: 100%;
  padding: 22px 0 16px;
  text-align: center;
  color: $font-dark;
  z-index: 100;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

  span {
    font-size: 40px;
  }
}
