@import '../assets/sharedStyles.scss';

.intro-section {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 560px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
  background-image: url('../assets/images/romantic-kiss.jpg');
  background-size: 120%;
  background-position: center bottom 0px;
  background-repeat: no-repeat;
  margin-top: -17px;
}

.intro-text {
  cursor: default;
  color: $font-light;
  font-family: "Montserrat", serif;
  text-align: center;
  font-weight: 200;
  font-size: 28px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  padding: 10px;
  border-radius: 8px;
      
  span {
    font-weight: 300;
    font-size: 33px;
    font-family: "Caveat", cursive;
  }
}

.contact-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #98919A;
  color: #ECEFEC;
  font-family: "Cardo", serif;
  font-size: 24px;
  text-decoration: none;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: none;
  opacity: 0.9;
  
  width: 170px;
  height: 50px;
  
  &:active {
    background-color: inherit;
    color: $font-light;
    text-decoration: none;
  }

  &:hover {
    color: white;
    background-color: #b4afb5;
  }
}

@media screen and (min-width: 440px) {
  .intro-section {
    height: 500px;
    background-position: center bottom -110px;
  }

  .intro-text {
    font-size: 38px;
    margin-top: 40px;
    span {
      font-size: 44px;
    }
  }

  .contact-button {
    bottom: 25px;
  }
}

@media screen and (min-width: 500px) {
  .intro-section {
    background-position: center bottom -170px;
  }
}

@media screen and (min-width: 560px) {
  .intro-section {
    background-position: center bottom -250px;
  }
}

@media screen and (min-width: 640px) {
  .intro-section {
    background-position: center bottom -300px;
  }
}

@media screen and (min-width: 700px) {
  .intro-section {
    background-position: center bottom -400px;
  }
}

@media screen and (min-width: 780px) {
  .intro-section {
    background-position: center bottom -490px;
  }
}

@media screen and (min-width: 860px) {
  .intro-section {
    background-position: center bottom -520px;
}
}

@media screen and (min-width: 900px) {
  .intro-section {
    background-position: center bottom -560px;
  }
}

@media screen and (min-width: 940px) {
  .intro-section {
    background-position: center bottom -450px;
    background-size: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .intro-section {
      background-image: url('../assets/images/romantic-kiss--large.jpg');
      width: 100vw;
      height: 350px;
      background-size: cover;
      background-position: center;
      padding-top: 42.16%;
    }
  .intro-text {
    margin-top: calc(-42.16% + 40px);
    font-size: 50px;
    font-weight: 300px;
    span {
      font-size: 60px;
    }
  }
}

// @media screen and (min-width: 1074px) {
//   .intro-section {
//     background-position: center bottom -560px;
//   }
// }

// @media screen and (min-width: 1130px) {
//   .intro-section {
//     background-position: center bottom -600px;
//   }
// }

// @media screen and (min-width: 1165px) {
//   .intro-section {
//     background-position: center bottom -620px;
//   }
// }

// @media screen and (min-width: 1190px) {
//   .intro-section {
//     background-position: center bottom -645px;
//   }
// }