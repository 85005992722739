@import '../assets/sharedStyles.scss';

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1001;
  height: 110px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.header-logo {
  height: 110px;
  width: 110px;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px 0px 16px;
  color: $font-dark;
  background-color: white;
  background-color: $background-logo-color;
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.5px;
  position: relative;
  z-index: 1001;
}

.dropdown-icon {
  height: 40px;
  cursor: pointer;
}

.nav-container {
  position: relative;
}

.dropdown {
  position: absolute;
  right: 0px;
  background-color: white;
  background-color: $background-logo-color;
  top: -210px;
  transition: top 0.3s ease-in-out; 
  z-index: 200;
  border-bottom-left-radius: 4px;
  width: 140px;
  visibility: hidden;
  &.active {
      top: -1px;
      visibility: visible;
    }

  .link {
    padding: 16px 12px 16px 26px;
    font-size: 20px;
    font-family: "Playfair Display", serif;
    color: $font-dark;
    display: block;
    text-decoration: none;
    

    &:active {
        color: inherit;
        background-color: inherit;
        text-decoration: none;
      }

    &:hover {
      text-decoration: underline;
    }
  }
}