@import '../assets/sharedStyles.scss';

.contact-header {
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Cardo", serif;
  color: $font-dark;
  text-align: center;
  max-width: 550px;
  &__title {
    margin-top: 30px;
    font-size: 38px;
    width: 100%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  p {
    margin: 25px 20px 25px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 200;
    font-family: "Montserrat", sans-serif;
  }
}

.contact-form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  background-image: url('../assets/images/field.jpg');
  background-size: 300%;
  background-position: left -450px top;
  background-repeat: no-repeat;
  
  form {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.2);
    margin: 10px 0 50px;
    border-radius: 8px;
    padding: 20px 22px 18px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 300;
    width: 85%;
    color: $font-dark;
  }

  label {
    font-style: italic;
    margin-bottom: 2px;
  }

  input {
    margin: 0 0 16px;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    padding: 2px 8px;
    background-color: #fffef74a;
    border: 1px solid black;
    border-radius: 4px;
    color: $font-dark;
  }

  textarea {
    height: 180px;
    margin-bottom: 16px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    padding: 8px 8px;
    box-sizing: border-box;
    background-color: #fffef74a;
    border: 1px solid black;
    border-radius: 4px;
  }

  .sendbutton {
    font-family: "Playfair Display", serif;
    padding: 10px 12px;
    font-size: 18px;
    border-radius: 8px;
    border: none;
    background-color: #fffef6;
    color: $font-dark;
    cursor: pointer;
  }

  .stateMessage {
    padding: 6px 8px;
    width: 90%;
    text-align: center;
    border-radius: 4px;
    line-height: 18px;

    background-color: rgb(45, 200, 50);
    &.error {
      background-color: rgb(212, 129, 129);
    }
  }
}

// should anyone see this and judge me... just know I'm judging me too

@media screen and (min-width: 440px) {
  .contact-form {
    background-position: left -540px top -60px;
  }
}

@media screen and (min-width: 500px) {
  .contact-form {
    background-position: left -650px top -120px;
  }
}

@media screen and (min-width: 560px) {
  .contact-form {
      background-position: left -650px top -190px;
    }
}

@media screen and (min-width: 640px) {
  .contact-form {
      background-position: left -750px top -290px;
    }
}

@media screen and (min-width: 740px) {
  .contact-form {
    background-position: left -350px top -90px;
    background-size: 200%;
  }
}

@media screen and (min-width: 840px) {
  .contact-form {
    background-position: left -450px top -190px;
    background-size: 200%;
  }
}

@media screen and (min-width: 900px) {
  .contact-form {
    background-position: left -500px top -350px;
    background-size: 200%;
  }
}

@media screen and (min-width: 1300px) {
  .contact-form {
    background-position: left -750px top -590px;
    background-size: 200%;
  }
}