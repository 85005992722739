@import './assets/sharedStyles.scss';

#root {
  display: flex;
  flex-direction: column;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
}

.content {
  overflow-y: auto;
  flex: 1;
  margin-top: 110px;
}

.image-2 {
  height: auto;
  width: 120%;
}



.section-4 {
  display: flex;
}

.end-photo {
  position: absolute;
  bottom: -150px;
  left: 20px;
  width: 80%;
  height: 180px;
  background-image: url('./assets/images/field.jpg');
  background-size: 150%;
  background-position: center;
  background-repeat: no-repeat;
}



