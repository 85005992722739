@import '../assets/sharedStyles.scss';

.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $background-logo-color;
  width: 100%;
}

.logo-image {
  height: 110px;
  margin: 10px 10px 10px 16px;
}

.contacts-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: end;
  margin-right: 10px;
  max-width: 300px;
  font-family: "Playfair Display", serif;
  color: $font-dark;
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: right;
  span {
      display: block;
    }
}

.contact-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.contact-title {
  font-style: italic;
  margin-bottom: 8px;
  font-size: 16px;
}

.contact-email {
  text-decoration: none;
  font-size: 12px;
  color: $font-dark;
  margin-bottom: 8px;
}

.telephone {
  text-decoration: none;
  font-weight: 300;
  font-size: 20px;
  color: $font-dark;
}

@media screen and (min-width: 600px) {
  .contacts-wrapper {
    margin-right: 16px;
  }  
}
