@import '../assets/sharedStyles.scss';

.about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $background-color;
  padding: 40px 30px 30px;
  width: auto;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-lex {
  cursor: default;
  font-family: "Cardo", serif;
  font-size: 38px;
  margin-bottom: 20px;
  color: $font-dark;
  scroll-margin: 135px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.lex-image-container {
  width: 245px;
  height: 260px;
  padding: 8px;
  border: 1px solid black;
  border-radius: 20px;
}

.lex-image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-image: url('../assets/images/lex.png');
  background-size: 100%;
  background-position: center top -30px;
  background-repeat: no-repeat;
}

.about-text {
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
  max-width: 450px;
}

.about-contact-button {
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: #98919A;
  width: 170px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Cardo", serif;
  font-size: 24px;
  color: $font-light;
  text-decoration: none;

  &:active {
    background-color: inherit;
    color: $font-light;
    text-decoration: none;
  }

  &:hover {
    color: white;
    background-color: #b4afb5;
  }
}

@media screen and (min-width: 440px) {
  .about-text {
    line-height: 28px;
  }
}

@media screen and (min-width: 900px) {
  .about-wrapper {
    flex-direction: row;
    gap: 60px;
    margin-top: 30px;
    align-items: center;
  }
  .lex-image-container {
    height: 370px;
    width: 320px;
  }
  .lex-image {
    background-position: center top -20px;
    
  }
  .about-text {
    margin-top: 0;
    max-height: 370px;
  }

  .about-contact-button {
    margin-top: 45px;
  }
}